import { useState } from "react";
import Link from "next/link";
import { Container, Row, Col } from "react-bootstrap";
import dynamic from "next/dynamic";
import Image from "next/image";
import styles from "@/styles/HealerCaraousel.module.css";
const OwlCarousel = dynamic(() => import("react-owl-carousel"), {
  ssr: false,
});

export default function HealerCaraousel({ props }) {
  const [healersList, setHealersList] = useState(props?.healersList ?? []);

  return (
    <>
      {healersList?.length ? (
        <section className={styles["choose-help"]}>
          <Container fluid>
            <Row>
              <Col md={8} className="m-auto mb-4">
                <div className="section-title">
                  <h3 className="title">Our Experienced healers</h3>
                  <p>
                    Our healers have lots of experience and deep understanding
                    of various energy healing modalities which will assure great
                    experience and satisfaction to each user based on their
                    needs.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={5} className={styles["counselling-therapy"]}>
                <div
                  className={
                    styles["inner-content"] + " d-flex align-items-center"
                  }
                >
                  <div>
                    <h4>Connect and schedule a session now</h4>
                    <p>
                      Our certified healers are available to give you healing
                      sessions which can help to improve mental wellness,
                      emotional well-being, self help and trauma healing. You
                      can schedule a Video/Audio or an In person session
                      whichever works best for you.
                    </p>
                    <ul>
                      <li key="v">
                        <div className={styles["icon"]}>
                          <Image
                            height={50}
                            width={50}
                            src="/images/video-session.svg"
                            alt="VideoSession"
                          />
                        </div>
                        <p>Video Session</p>
                      </li>
                      <li key="a">
                        <div className={styles["icon"]}>
                          <Image
                            height={50}
                            width={50}
                            src="/images/audio-session.svg"
                            alt="AudioSession"
                          />
                        </div>
                        <p>Audio Session</p>
                      </li>
                      <li key="p">
                        <div className={styles["icon"]}>
                          <Image
                            height={50}
                            width={50}
                            src="/images/person-person.svg"
                            alt="InPerson"
                          />
                        </div>
                        <p>In Person</p>
                      </li>
                    </ul>
                    <Link href={"/healers"} className="theme-btn">
                      View All <i className="ri-arrow-right-line"></i>{" "}
                    </Link>
                  </div>
                </div>
              </Col>
              <Col md={7} className="d-flex align-items-center">
                {healersList?.length ? (
                  <OwlCarousel
                    className="our-doctor owl-theme"
                    loop={false}
                    margin={15}
                    nav={false}
                    dots={true}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      375: {
                        items: 2,
                      },
                      991: {
                        items: 3,
                      },
                      1200: {
                        items: 3,
                      },
                    }}
                  >
                    {healersList?.map((item, index) => {
                      return (
                        <div className="item" key={"j-" + index}>
                          <div className={styles["doctor-card"]}>
                            <Link
                              href={`/healers/${item.slug}/detail`}
                            >
                              <div className={styles["doctor-image"]}>
                                <Image
                                  height={330}
                                  width={310}
                                  src={item.image ?? "/images/dummy-user.svg"}
                                  alt="Healer Image"
                                />
                              </div>
                            </Link>
                            <div className={styles["doctor-content"]}>
                              <Link
                                href={`/healers/${item.slug}/detail`}
                              >
                                <div className={styles["doctor-name"]}>
                                  <h4>{item.name}</h4>
                                  <i className="ri-checkbox-circle-fill"></i>
                                </div>
                              </Link>
                              <p className={styles["title"]}>
                                {item.profile_tag ?? "Not Specified"}
                              </p>
                              <div className={styles["feedback"]}>
                                <span>
                                  {[...Array(5).keys()].map((i, j) => (
                                    <>
                                      <i
                                        key={j}
                                        className={`ri-star-fill ${
                                          i + 1 <= item.rating
                                            ? "star-fill"
                                            : ""
                                        }`}
                                      ></i>
                                    </>
                                  ))}
                                </span>
                                <p>{item.feedback ?? 0} Feedback</p>
                              </div>
                              <div className={styles["doctor-info"]}>
                                <ul>
                                  <li key="cal">
                                    <i className="ri-calendar-line"></i>{" "}
                                    {item.experience && item.experience != ""
                                      ? item.experience > 1
                                        ? item.experience + " Years"
                                        : item.experience + " Year"
                                      : "0 Year"}
                                  </li>
                                  <li key="map">
                                    <i className="ri-map-pin-line"></i>{" "}
                                    {(item.state ? item.state : "-") +
                                      (item.country
                                        ? ", " + item.country
                                        : "-")}
                                  </li>
                                  <li key="use">
                                    <i className="ri-user-3-line"></i>{" "}
                                    {item.sessions && item.sessions != ""
                                      ? item.sessions
                                      : "Not Specified"}
                                  </li>
                                </ul>
                              </div>
                              <Link
                                href={`/healers/${item.slug}/slots`}
                                className={styles["theme-btn"]}
                              >
                                Book Now <i className="ri-arrow-right-line"></i>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                ) : (
                  <h5>Records not available to display here.</h5>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
